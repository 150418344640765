import { MovementType } from '@/core/enums/movement-types';
import Modules from '@/store/enums/Modules';
import store from '@/store';
import { Action, Module, Mutation, MutationAction, VuexModule, getModule } from "vuex-module-decorators";
import PaginationRequest from '@/models/general/PaginationRequest';
import { Mutations } from '@/store/commons/MutationsTable';
import { SaveValuesToStore } from '@/core/shared/AssingProps';
import { Actions } from '@/store/commons/ActionsTable';
import ReceptionRow from '@/models/receptions/ReceptionRow';
import OptionSelect from '@/models/shared/OptionSelect';
import CustomerComboService from '@/core/services/customer/CustomerComboService';
import TableWarehouseTransfersState from '../../models/TableWarehouseTransfersState';
import TransferRow from '@/models/weewhouse/TransferRow';
import FiltersTransfers from '@/models/weewhouse/filters/FiltersTransfers';
import WarehouseTransfersService from '@/core/services/werehouse/WarehouseTransfersService';
import WarehouseComboService from '@/core/services/werehouse/WarehouseComboService';
import { TransferStatus, getOptions } from '@/core/enums/transfer-status';
import { AxiosResponse } from 'axios';
import MovementTypeComboService from '@/core/services/movementTypes/MovementTypeComboService';

enum MutationsOwner { 
    SET_SELECT_ROW = "SET_SELECT_ROW"
    , RESET_SELECT_ROW = "RESET_SELECT_ROW"
}
const defaultRow = {
    id: ''
    , destinationWarehouseId: ''
    , destinationWarehouseName: ''
    , movementTypeId: ''
    , movementTypeName: ''
    , originWarehouseId: ''
    , originWarehouseName: ''
    , createdAt: ''
    , folio: ''
    , status: null
    , statusName: ''
} as TransferRow;
const defaultFilters = {
    folio: undefined
    , customerIds: []
    , productIds: []
    , endDate: ''
    , startDate: ''
    , customerFolio: ''
    , ocFolio: ''
    , filterType: ''
    , status: ''
} as FiltersTransfers

@Module({dynamic: true, store, namespaced: true, name: Modules.TableTransfersModule})
class TableTransfersModule extends VuexModule implements TableWarehouseTransfersState {
    selects = {
        customerOptions: [] as OptionSelect[]
        , movementOptions: [] as OptionSelect[]
    };
    rowSelected = defaultRow;
    records = [] as TransferRow[];
    filters = defaultFilters;
    totalRecords = 0;
    pagination = {
        currentPage: 1
        , pageSize: 10
    } as PaginationRequest;
    loading = false;
   
     get getSelectedFolio(): string { 
        return this.rowSelected.folio;
    }
    get getSelectedRow(): TransferRow { 
        return this.rowSelected;
    }
    get getRecordsTable(): TransferRow[] { 
        return this.records;
    }
    get getRowSelected(): TransferRow { 
        return this.rowSelected;
    }
    get canAddDetails(): boolean { 
        return this.rowSelected.status == TransferStatus.Capture;
    }
    get isInCapture(): boolean { 
        return this.rowSelected.status == TransferStatus.Capture;
    }
    get isInCaptureStatus() { 
        return (status: TransferStatus) => { 
            return status == TransferStatus.Capture
        }
    }
    @Mutation
    [MutationsOwner.SET_SELECT_ROW](value: TransferRow | undefined) {
        if (value) { 
            this.rowSelected = value;
        }
    }
    @Mutation
    [MutationsOwner.RESET_SELECT_ROW](){
        this.rowSelected = defaultRow;
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: TransferRow[]){
        this.records = table;
    }
     @Mutation
    [Mutations.RESET_FILTERS](){
         this.filters = defaultFilters;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: Partial<PaginationRequest>){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: Partial<FiltersTransfers>) {      
        SaveValuesToStore(this.filters, filters);
    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }

     /**
     * Se obtienen los datos de la tabla de facturas creadas mediante la paginación
     */
     @Action
     async [Actions.SEARCH_TABLE_DATA]() {
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
         const resp = (await (service.searchTableTransfers(this.pagination, this.filters)
             .finally(() => this.context.commit(Mutations.SET_VALUE_LOADING, false)))).data ??
             {
                 data: [] as ReceptionRow[]
                , totalPages: 0
                , currentPage: this.pagination.currentPage
         }
        const data = resp.data.map(r => { return {...r, statusName: getOptions().find(o => o.id == r.status)?.name ?? '' } })
         this.context.commit(Mutations.SET_TABLE_DATA, data);
         this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
         this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalRecords);
    }
 
     /**
        * Realiza un busqueda con la nueva paginación
        * @param pagination paginación de la tabla
        */
     @Action
     async [Actions.CHANGE_PAGE](pagination: Partial<PaginationRequest>){
           this.context.commit(Mutations.SET_PAGINATION, pagination);
           this.context.dispatch(Actions.SEARCH_TABLE_DATA);
     }
 
     /**
      * Filtros que se aplican y despues se hace la busqueda de los datos
      * @param filters filtros nuevos
      */
     @Action
     async [Actions.UPDATE_FILTERS](filters: Partial<FiltersTransfers>) {
         //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
         this.context.commit(Mutations.SET_FILTERS, filters);
         this.context.commit(Mutations.SET_PAGINATION, {
           currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
         });
         this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
    
     @MutationAction
     async getComboCustomer(filterName: string) {
         const comboOptions = await customerService.searchCombo(filterName);
         const selects = { ...this.selects }
        //if(filterName.trim() == '') return  { selects: { ...selects } }
        
        return { selects: { ...selects, customerOptions: comboOptions.data ?? [] } }
    }
     @MutationAction
     async getComboMovementTypes(filterName: string) {
         const comboOptions = await movementTypeService.searchCombo(MovementType.Transfer, filterName);
         const selects = { ...this.selects }
        //if(filterName.trim() == '') return  { selects: { ...selects } }
        
        return { selects: { ...selects, movementOptions: comboOptions.data ?? [] } }
    }

     @Action
     async searchInfoTransferSelected(id: string) {
         return service.getInfo(id)
             .then((reception) => { 
                 let isCorrect = false;
                if (reception) { 
                    isCorrect = true;
                    this.context.commit(MutationsOwner.SET_SELECT_ROW, reception.data ?? defaultRow);
                }
                 return isCorrect;
             })
    }
    /*@Action
    async sendCommitted() {
         return service.committed(this.rowSelected.id)
             .then((resp) => { 
                if (resp.data) {
                     this.context.commit(MutationsOwner.RESET_SELECT_ROW, {});
                 }
                 return resp.data;
             })
    }*/
    /**
     * Cambia el estatus 
     * @param id identificador de la entrada
     * @param newStatus nuevo estatus a asignarle
     */
    @Action
    async [Actions.CHANGE_STATUS](newStatus: TransferStatus) {
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        let action: Promise<Partial<AxiosResponse<boolean>>> = Promise.resolve({data: false});

        if (newStatus == TransferStatus.EndCapture) {
            action = service.sendEndCapture(this.rowSelected.id);
        } else if (newStatus == TransferStatus.Committed) {
            action = service.committed(this.rowSelected.id);
        }
        
        return action
            .then((resp) => { 
                if (resp.data) {
                    this.context.dispatch(Actions.SEARCH_TABLE_DATA)
                    this.context.commit(MutationsOwner.RESET_SELECT_ROW, {});
                 }
                 return resp.data;
            })
        .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false));
        
    }
} 

const service = new WarehouseTransfersService();
const customerService = new CustomerComboService();
const movementTypeService = new MovementTypeComboService();


export default getModule(TableTransfersModule);
