
import  { defineComponent, PropType } from 'vue'
import OptionSelect from "src/models/shared/OptionSelect";
import { useField } from "vee-validate";

export default defineComponent({
    setup(props){
        const { value, errorMessage } = useField(props.name);
        const key_control = Math.random().toString(36).slice(2, 7);
        return {
            value
            , errorMessage
            , key_control
        }
    },
    props:{
        options: {
            type: Array as PropType<Array<OptionSelect>>
            , required: true
        },
        name: {
            type: String
            , required: true
        },
        placeholder: {
            type: String
            , required: true
        },
        disabled: {
            type: Boolean
            , default: false
        },
        loading: {
            type: Boolean
            , default: false
        },
        multiple: {
            type: Boolean
            , default: false
        }
    },
    emits: ['search','change','clear']
})
