<template>
    <div v-show="showReadBottom" class="btn btn-icon btn-sm btn-active-icon-primary" @click="$emit('action-crud', FormTypes.Read)">
        <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotune/files/fil024.svg" />
        </span>
    </div>
    <div v-show="showUpdateBottom" class="btn btn-icon btn-sm btn-active-icon-primary" @click="$emit('action-crud', FormTypes.Update)">
        <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotune/art/art005.svg" />
        </span>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { FormTypes } from "@/core/enums/form-types"
export default defineComponent({
    props: {
        showUpdateBottom: {
            type: Boolean,
            default: true
        },
        showReadBottom: {
            type: Boolean,
            default: true
        }
    },
    emits: ['action-crud'],
    setup(props) {
        //const { showUpdateBottom, showReadBottom } = toRefs(props);
        return {
            FormTypes
        }
    },
})
</script>
