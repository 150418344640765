import { Modal, Collapse } from "bootstrap";
const hideModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getInstance(modalEl);
  myModal.hide();
};

const hideModalById = (id: any): void => {

  const myModalEl = document.querySelector(id);

  const myModal = Modal.getInstance(myModalEl);
  if (!myModal) {
    return;
  }
  
  myModal.hide();
};

const listenerHideModal = (id: any, callbackCrudAction: any): void => {

const myModalEl = document.querySelector(id)

if (!myModalEl) {
  return;
}

myModalEl.addEventListener('hidden.bs.modal', function (event) {
  callbackCrudAction();
});

}

const showModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getInstance(modalEl);
  myModal.show();
};

const showModalById = (id: any): void => {
  const myModalEl = document.querySelector(id);

  const myModal = Modal.getInstance(myModalEl);

  if (!myModal) {
    return;
  }
  
  myModal.show();
};

const removeModalBackdrop = (): void => {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
};

const toggleCollapse = (id: string): void => {
    const collapse = document.querySelector(id);
   
    const collapseInst = Collapse.getInstance(collapse);
    if (!collapseInst) {
      return;
   }
   collapseInst.toggle()
}

export { 
  removeModalBackdrop,
  hideModal,
  showModal,
  hideModalById,
  listenerHideModal,
  showModalById
  , toggleCollapse
 };


