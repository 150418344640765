import Modules from '@/store/enums/Modules';
import GeneralTableState from "@/models/general/state/GeneralTableState";
import { ActionContext } from "vuex";
import * as Table from '@/store/commons/ActionsTable';

 /**
  * OBSOLE
  * Recarga los datos de la tabla con base a un dueño que es la liquidación
  * @param context contexto donde esta el store
  * @param moduleName  nombre del modulo que contiene los datos de la tabla
  */
function realoadTableOwnerSettlement(context: ActionContext<ThisType<any>, any>, moduleName: Modules) {
    const tableStore = context.rootState[moduleName] as GeneralTableState<any>;
    if(tableStore){
        context.dispatch(moduleName + '/' + Table.Actions.SEARCH_TABLE_DATA, {}, { root: true });
    }
      
 }

  /**
  * Recarga los datos de la tabla
  * @param context contexto donde esta el store
  * @param moduleName  nombre del modulo que contiene los datos de la tabla
  */
function realoadTable(context: ActionContext<ThisType<any>, any>, moduleName: Modules) {
    const tableStore = context.rootState[moduleName] as GeneralTableState<any>;
    if(tableStore){
        context.dispatch(moduleName + '/' + Table.Actions.SEARCH_TABLE_DATA, {}, { root: true });
    }
      
 }

 export { realoadTableOwnerSettlement, realoadTable }